import venueByCategory from "../blog/venue-by-category";

export const all_routes = {
  home: "/",

  // Blog Routes
  // blogGrid: "/blog/blog-grid",
  blogGrid: "/blog",
  blogGridSidebarRight: "/blog/blog-grid-sidebar-right",
  blogGridSidebarLeft: "/blog/blog-grid-sidebar-left",
  blogList: "/personal-training",
  blogListSidebarRight: "/blog/blog-list-sidebar-right",
  blogListSidebarLeft: "/sports-venue",
  blogCarousel: "/blog/blog-carousel",

  // Pages Routes
  aboutUs: "/pages/about-us",
  comingSoon: "/pages/coming-soon",
  error404: "/pages/error-404",
  events: "/events",
  serviceDetail: "/pages/service-detail",
  services: "/pages/services",
  termsCondition: "/terms-condition",
  testimonials: "/pages/testimonials",
  testimonialsCarousel: "/pages/testimonials-carousel",
  maintenance: "/pages/maintenance",
  privacyPolicy: "/privacy-policy",
  pricing: "/pages/pricing",
  ourTeams: "/pages/our-teams",
  myProfile: "/pages/my-profile",
  faq: "/pages/faq",
  gallery: "/pages/gallery",
  invoice: "/coaches/invoice",
  lessonOrderConfirm: "/coaches/lesson-order-confirm",
  lessonPayment: "coaches/lesson-payment",
  lessonPersonalinfo: "coaches/lesson-personalinfo",
  userWallet: "/user/user-wallet",

  // Coaches Routes
  bookingCancelled: "/coaches/booking-cancelled",
  bookingCompleted: "/coaches/booking-completed",
  allCourt: "/coaches/all-court",
  appointmentDetails: "/coaches/appointment-details",
  addCourt: "/coaches/add-court",
  activeCourt: "/coaches/active-court",
  inactiveCourt: "/coaches/inactive-court",
  coachDetails: "/coaches/coach-details",
  coachEarning: "/coaches/coach-earning",
  coachesGrid: "/coaches",
  coachesGridSidebar: "/coaches/coaches-sidebar-grid",
  coachesList: "/coaches/coaches-list",
  coachesListSidebar: "/coaches/coaches-sidebar-list",
  coachesMap: "/coaches/coaches-map",
  coachRequest: "/coaches/coach-request",
  coachWallet: "/coaches/coach-wallet",
  coachProfile: "/coaches/coach-profile",
  coachPersonalInfo: "/coaches/coach-personalinfo",
  coachesMapSidebar: "/coaches/coaches-map-sidebar",
  earningCoaching: "/coaches/earning-coaching",
  cagecheckout: "/coaches/cage-checkout",
  cagedetails: "/coaches/cage-details",
  cageordeconfirm: "/coaches/cage-order-confirm",
  cagepersonalinfo: "/coaches/cage-personalinfo",
  lessonTimeDate: "/coaches/lesson-timedate",
  lessonType: "/coaches/lesson-type",
  settingLesson: "/coaches/setting-lesson",
  settingPassword: "/coaches/setting-password",
  settingAvalibilty: "/coaches/setting-availability",
  profileOtherSetting: "/coaches/profile-othersetting",
  coachDashboard: "coaches/coach-dashboard",
  coachBooking: "/coaches/coach-booking",
  coachChat: "/coaches/coach-chat",

  // User Routes
  userComplete: "/user/user-complete",
  userDashboardProfiles: "/user/user-dashboard-profiles",
  userDashboard: "/user/user-dashboard",
  userInvoice: "/user/user-invoice",
  userBookings: "/user/user-bookings",
  userChat: "/user/user-chat",
  userCoaches: "/user/user-coaches",
  userProfile: "/user/user-profile",
  userSettingPassword: "/user/user-setting-password",
  userProfileOtherSetting: "/user/user-profile-othersetting",
  userCancelled: "/user/user-cancelled",
  userOngoing: "/user/user-ongoing",

  // Listing Routes
  listingGridSidebar: "/listing/listing-grid-sidebar",
  listingGrid: "/listing/listing-grid",
  listingListSidebar: "/listing/listing-list-sidebar",
  listingList: "/listing/listing-list",
  listingMapSidebar: "/listing/listing-map-sidebar",
  listingMap: "/listing/listing-map",

  // Auth Routes
  register: "/register",
  login: "/login",
  verifyotp: "/auth/verifyotp",
  changePassword: "/auth/change-password",
  forgotPasssword: "/auth/forgot-password",

  //contact-us
  contactUs: "/contact-us",
  refundPolicy: "/refund-policy",
  paymentSuccess: "/payment-success",
  paymentFailed: "/payment-failed",
  commonpage: "/go-to",
  venueByCategory: "/sports-venue/:type",

  venueDetails: "/sports-venue/:type/:name/:id",
  CoachTimeDate: "/coaches/coach-timedate/:id",
  CoachPayment: "/coaches/coach-payment/:id",
  CoachOrderConfirm: "/coaches/coach-order-confirm/:id",
  VenueTimeDate: "/sports-venue/venue-timedate/:id",
  VenuePayment: "/sportsvenue/venue-payment/:id",
  VenueOrderConfirm: "/sports-venue/venue-confirm/:id",
  TrainingTimeDate: "/personal-training/training-timedate/:id",
  TrainingOrderConfirm: "/personal-training/training-order-confirm/:id",
  TrainingPayment: "/personal-training/training-payment/:id",
  coachDetail: "/coaches/:type/:name/:id",
  blogDetails: "/blog/venue-details/:id",
  personalTrainingDetails: "/personal-training/trainer/:name/:id",
  eventdetails: "/events/event-details/:id",
  blogDetailsSidebarLeft: "/blog/:slugName",
};
